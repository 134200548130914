import React from 'react'

export const LogoIcon = () => (
  <svg width="130" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M69.67 6.351a2.971 2.971 0 1 1-2.97-2.983 2.976 2.976 0 0 1 2.97 2.983ZM77.355 6.351a2.97 2.97 0 1 1-5.939-.024 2.97 2.97 0 0 1 5.939.024Z"
      fill="#F4D400"
    />
    <path
      d="m48.909 16.462 3.746-4.553c.271-.327.117-.696-.29-.696h-2.81a.98.98 0 0 0-.763.382l-2.563 3.271v-3.08a.543.543 0 0 0-.617-.616h-2.058a.536.536 0 0 0-.616.616v10.573a.535.535 0 0 0 .616.616h2.07a.536.536 0 0 0 .617-.616v-4.085l3.457 4.313a.893.893 0 0 0 .696.407h2.92a.413.413 0 0 0 .315-.678l-4.72-5.854ZM106.921 11.162h-8.793a.501.501 0 0 0-.501.502v2.283a.501.501 0 0 0 .501.501h2.772v8.044a.501.501 0 0 0 .502.502h2.264a.502.502 0 0 0 .502-.502v-8.044h2.741a.5.5 0 0 0 .501-.501v-2.283a.501.501 0 0 0-.501-.502M80.967 20.357h-2.829v-2.215h2.83c1.73 0 1.86 2.215 0 2.215Zm-2.829-6.551h2.134a.93.93 0 1 1 0 1.86h-2.134v-1.86Zm5.348 2.73c2.09-2.01.695-5.373-2.519-5.373h-5.522a.502.502 0 0 0-.502.502v10.826a.502.502 0 0 0 .502.503h6.062c4.206 0 5.33-4.852 1.979-6.459ZM72.352 11.665a.503.503 0 0 0-.502-.502h-2.612a.502.502 0 0 0-.503.502v10.826a.503.503 0 0 0 .503.503h2.612a.503.503 0 0 0 .502-.503V11.665ZM124.945 16.614h-1.771v-2.726h1.728c1.164 0 1.567.545 1.567 1.332 0 .675-.31 1.375-1.524 1.375v.019Zm.248-5.451h-4.819a.502.502 0 0 0-.502.501v10.828a.502.502 0 0 0 .502.502h2.286a.5.5 0 0 0 .501-.502v-3.196h1.859c2.651 0 4.664-1.394 4.664-4.033 0-2.477-2.063-4.082-4.528-4.082M111.296 18.33l1.298-3.87 1.298 3.87h-2.596Zm7.503 4.041-4.005-10.753a.62.62 0 0 0-.661-.452h-3.047a.616.616 0 0 0-.668.452l-4.017 10.753a.41.41 0 0 0 .178.585c.07.032.148.043.224.033h2.559a.564.564 0 0 0 .494-.358l.525-1.576h4.413l.519 1.576a.566.566 0 0 0 .494.358h2.559a.407.407 0 0 0 .408-.618M66.227 22.491V11.665a.502.502 0 0 0-.502-.502h-2.482a.564.564 0 0 0-.465.254l-4.653 6.34v-6.092a.502.502 0 0 0-.503-.502h-2.295a.502.502 0 0 0-.503.502v10.826a.503.503 0 0 0 .503.503h2.519a.565.565 0 0 0 .459-.255l4.653-6.34v6.092a.503.503 0 0 0 .502.503h2.29a.509.509 0 0 0 .508-.503h-.03Z"
      fill="#0087E6"
    />
    <path
      d="m96.86 20.75-1.356-1.43a.501.501 0 0 0-.73-.049 3.923 3.923 0 0 1-2.636.997 3.175 3.175 0 0 1 0-6.35 3.904 3.904 0 0 1 2.636.99.495.495 0 0 0 .73-.049l1.355-1.429a.502.502 0 0 0-.012-.7 6.475 6.475 0 0 0-4.672-1.942 6.293 6.293 0 0 0 0 12.58 6.467 6.467 0 0 0 4.684-1.918.49.49 0 0 0 .149-.36.483.483 0 0 0-.136-.34h-.013ZM16.496 12.287h-.009a2.127 2.127 0 0 0 2.115-2.134v-7.83a2.125 2.125 0 1 0-4.23 0v7.83a2.13 2.13 0 0 0 2.115 2.134h-.01.02ZM11.534 15.91a2.121 2.121 0 0 1-2.675 1.373l-7.406-2.42a2.133 2.133 0 0 1 1.311-4.061l7.407 2.42a2.134 2.134 0 0 1 1.363 2.689ZM21.861 16.638a2.12 2.12 0 0 1-.402-.728 2.139 2.139 0 0 1 1.363-2.688l7.411-2.42a2.134 2.134 0 0 1 1.312 4.06l-7.407 2.42a2.121 2.121 0 0 1-2.277-.644ZM6.35 31.574a2.143 2.143 0 0 1-.47-2.984l4.577-6.311a2.12 2.12 0 0 1 3.816.925c.088.559-.049 1.13-.38 1.589L9.32 31.1a2.126 2.126 0 0 1-2.97.47v.004ZM27.493 30.18a2.143 2.143 0 0 1-.85 1.394v.02a2.12 2.12 0 0 1-2.966-.47L19.1 24.787a2.129 2.129 0 1 1 3.44-2.51l4.573 6.312c.332.459.468 1.03.38 1.59Z"
      fill="#0087E6"
    />
  </svg>
)
