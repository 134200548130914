import { useCallback, useMemo, useState } from 'react'

import { ModalContextProps, ModalProps, OpenModalFunction } from '../context'
import { modalComponents } from '../modalComponents'
import { ModalType } from '../modalType'

export const useModalState = (): ModalContextProps => {
  const [modalType, setModalType] = useState<ModalContextProps['modalType']>(null)
  const [modalProps, setModalProps] = useState<ModalContextProps['modalProps']>(null)
  const [modalComponent, setModalComponent] = useState<ModalContextProps['modalComponent']>(null)

  const openModal: OpenModalFunction = useCallback(
    type => () => {
      setModalType(type)
      setModalComponent(modalComponents[type])
    },
    [],
  )

  const updateModalProps = useCallback((props: ModalProps) => {
    setModalProps(props)
  }, [])

  const closeModal: VoidFunction = useCallback(() => {
    setModalType(null)
    setModalProps(null)
    setModalComponent(null)

    if (modalType === ModalType.SuccessOrderExtraMoney) {
      window.location.reload()
    }
  }, [modalType])

  return useMemo<ModalContextProps>(
    () => ({
      openModal,
      closeModal,
      modalType,
      modalComponent,
      modalProps,
      updateModalProps,
    }),
    [modalComponent, modalProps, modalType, openModal, updateModalProps, closeModal],
  )
}
