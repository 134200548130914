import { CreditType } from 'src/api/extraMoney'

export class FeeFilter {
  public with(credits: CreditType[]): CreditType[] {
    return this.sortAsc(credits.filter(credit => credit.fee > 0))
  }

  public without(credits: CreditType[]): CreditType[] {
    return this.sortAsc(credits.filter(credit => !credit.fee || credit.fee === 0))
  }

  private sortAsc(credits: CreditType[]): CreditType[] {
    return credits.sort((prev, next) => prev.value - next.value)
  }
}
