import React, { PropsWithChildren } from 'react'
import { useLinkClickHandler } from 'react-router-dom'
import { LinkButton } from 'ks-react-design-system'

type RouterLinkProps = PropsWithChildren & {
  to: string
  className?: string
}

export const RouterLink: React.FC<RouterLinkProps> = ({ to, className, children }) => {
  const handleCLick = useLinkClickHandler(to)

  return (
    <LinkButton
      href={to}
      onClick={handleCLick}
      buttonSize="s"
      variant="primary"
      className={className}
    >
      {children}
    </LinkButton>
  )
}
