import { useExtraMoney } from 'src/context/extraMoneyContext'
import { ModalType, useModal } from 'src/context/modalContext'
import { ModalWrapper } from '../ModalWrapper'
import { SelectedCredit } from '../SelectedCredit'
import content from './content'
import { ExtraMoneyCredits } from './ExtraMoneyCredits'
import { PayOptionsList } from './PayOptionsList'
import { useDefaultCredit } from './useDefaultCredit'

export const ExtraMoneyModal = () => {
  const { openModal } = useModal()
  const { creditsWithFee, creditsWithoutFee } = useExtraMoney()

  useDefaultCredit({ creditsWithoutFee, creditsWithFee })

  const { withoutFeeLabel, withFeeLabel, title, footerNext } = content

  return (
    <ModalWrapper
      title={title}
      footerProps={{ text: footerNext, handler: openModal(ModalType.ExtraMoneyPrecheck) }}
    >
      <PayOptionsList />

      <ExtraMoneyCredits title={withoutFeeLabel} credits={creditsWithoutFee} />
      <ExtraMoneyCredits title={withFeeLabel} credits={creditsWithFee} />

      <SelectedCredit />
    </ModalWrapper>
  )
}
