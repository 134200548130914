import axios, { AxiosError, AxiosInstance } from 'axios'

import { logger } from 'src/logger'

export type SubscriptionType = {
  msisdn: string
  msisdnHash: string
  isGsmPrepaid: boolean
  ownerType: string
  paymentType: string
  subscriptionType: string
}

export type SubscriptionResponseType = {
  data: SubscriptionType | null
  error: number | null
}

export class Subscription {
  public async get(): Promise<SubscriptionResponseType> {
    try {
      const response = await this.instance().get<SubscriptionType>(`/api/subscription`)

      return {
        data: response.data,
        error: null,
      }
    } catch (err) {
      const error = err as AxiosError

      logger.error(error, 'Subscription.get()')

      return {
        data: null,
        error: error.response?.status || null,
      }
    }
  }

  private instance(): AxiosInstance {
    return axios.create()
  }
}
