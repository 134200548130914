import { memo } from 'react'
import classNames from 'classnames'

import { useExtraMoney } from 'src/context/extraMoneyContext'
import { ModalType, useModal } from 'src/context/modalContext'
import { withCurrency } from '../withCurrency'
import content from './content'

import styles from './SelectedCredit.module.scss'

const { extraMoney, fee } = content

export const SelectedCredit = memo(() => {
  const { selectedCredit } = useExtraMoney()
  const { modalType } = useModal()

  if (!selectedCredit) {
    return null
  }

  const wrapperClassName = classNames(styles.wrapper, {
    [styles.wrapperBorderTop]: modalType === ModalType.ExtraMoney,
    [styles.wrapperBorderBottom]: modalType === ModalType.ExtraMoneyPrecheck,
  })

  return (
    <div className={wrapperClassName}>
      <span className={styles.label}>
        <span>{extraMoney}</span>
        <span>{withCurrency(selectedCredit.value)}</span>
      </span>
      <span className={styles.label}>
        <span>{fee}</span>
        <span>{withCurrency(selectedCredit.fee)}</span>
      </span>
    </div>
  )
})
