import { useCallback, useEffect } from 'react'

import { modalAnalyticsEvent } from 'src/analytics/events/modal'
import { showcaseAnalyticsEvent } from 'src/analytics/events/showcase'
import { StateVariant } from 'src/api/extraMoney'
import { useExtraMoney } from 'src/context/extraMoneyContext'
import { ModalType, useModal } from 'src/context/modalContext'

export const useHandlers = (): { openModalHandler: () => Promise<void> } => {
  const { openModal } = useModal()
  const { fetchCredits, credits, creditsWithFee, creditsWithoutFee, creditsState } = useExtraMoney()

  const openCorrectModal = useCallback(() => {
    if (creditsState === StateVariant.Active) {
      modalAnalyticsEvent.openDebt()
      openModal(ModalType.ExtraMoneyDebt)()

      return
    }

    modalAnalyticsEvent.openCredits({ creditsWithFee, creditsWithoutFee })
    openModal(ModalType.ExtraMoney)()
  }, [creditsState, creditsWithFee, creditsWithoutFee, openModal])

  useEffect(() => {
    if (credits) {
      openCorrectModal()
    }
  }, [credits, openCorrectModal])

  const openModalHandler = useCallback(async () => {
    showcaseAnalyticsEvent.clickShowExtraMoney()

    const { error } = await fetchCredits()

    if (error !== null) {
      modalAnalyticsEvent.openError(error)
      openModal(ModalType.Error)()

      return
    }

    if (credits) {
      openCorrectModal()
    }
  }, [credits, fetchCredits, openCorrectModal, openModal])

  return {
    openModalHandler,
  }
}
