import { Tag } from 'ks-react-design-system'

import { useSubscription } from 'src/context/subscriptionContext'
import content from './content'

import styles from './TopUpTitle.module.scss'

export const TopUpTitle = () => {
  const { subscriptionData, shownSubscriptionNumber } = useSubscription()
  const subscriptionId = subscriptionData?.msisdn

  return (
    <div className={styles.wrapper}>
      {subscriptionId && (
        <Tag type="info" tagSize="m" light className={styles.tag}>
          {content.byPhoneNumber} {shownSubscriptionNumber}
        </Tag>
      )}

      <div className={styles.textWrapper}>
        <p className={styles.title}>
          {subscriptionId ? content.title.authorized : content.title.unauthorized}
        </p>

        <p className={styles.description}>
          {subscriptionId ? content.description.authorized : content.description.unauthorized}
        </p>
      </div>
    </div>
  )
}
