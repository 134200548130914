/* eslint-disable no-console */

interface Logger {
  log(logInfo: string): void
  error(error: Record<string, any> | string, additionalInfo?: string): void
}

class MainLogger implements Logger {
  public log(logInfo: string) {
    console.log(JSON.stringify({ logInfo }))
  }

  public error(error: Record<string, any> | string, additionalInfo?: string): void {
    if (typeof error === 'string') {
      this.log(error + additionalInfo)
    } else if (typeof error === 'object') {
      console.log(this.createErrorLog(error, additionalInfo))
    }
  }

  private createErrorLog(error: Record<string, any>, additionalInfo?: string): string {
    const status = error?.response?.status || error?.statusCode || error?.status || 'no status'
    const message = error?.message || 'no message'

    return JSON.stringify({
      logInfo: `Message: ${message}. Status: ${status}. Detail: ${additionalInfo || 'no detail'}`,
      status,
      message,
      response: error?.response?.data || error?.data || 'no data',
    })
  }
}

export const logger = new MainLogger()
