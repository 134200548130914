import React, { PropsWithChildren } from 'react'

import { CreditType, StateVariant } from 'src/api/extraMoney'
import { useExtraMoneyState } from './hooks/useExtraMoneyState'

export type ExtraMoneyContextProps = {
  loading: boolean
  credits: CreditType[] | null
  creditsWithFee: CreditType[]
  creditsWithoutFee: CreditType[]
  creditsState: StateVariant | null
  selectedCredit: CreditType | null
  changeActiveCredit: (credit: CreditType) => void
  fetchCredits: () => Promise<{ error: number | null }>
}

export const ExtraMoneyContext = React.createContext<ExtraMoneyContextProps | null>(null)

ExtraMoneyContext.displayName = 'ExtraMoneyContext'

export const ExtraMoneyProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const value = useExtraMoneyState()

  return <ExtraMoneyContext.Provider value={value}>{children}</ExtraMoneyContext.Provider>
}
