import { modalAnalyticsEvent } from 'src/analytics/events/modal'
import { ExtraMoney } from 'src/api/extraMoney'
import { useExtraMoney } from 'src/context/extraMoneyContext'
import { ModalType, useModal } from 'src/context/modalContext'

const apiExtraMoney = new ExtraMoney()

export type UseHandleOrderReturnType = {
  handleOrder: () => Promise<void>
}

export const useHandleOrder = (): UseHandleOrderReturnType => {
  const { selectedCredit, creditsWithFee, creditsWithoutFee } = useExtraMoney()
  const { openModal } = useModal()

  const handleOrder = async () => {
    if (selectedCredit) {
      modalAnalyticsEvent.orderConfirm({
        selectedCredit,
        creditsWithFee,
        creditsWithoutFee,
      })

      const { data: responseData, error } = await apiExtraMoney.order(
        selectedCredit.serviceId,
        selectedCredit.value,
      )

      if (responseData?.orderId) {
        modalAnalyticsEvent.orderSuccess({
          selectedCredit,
          creditsWithFee,
          creditsWithoutFee,
          orderId: responseData.orderId,
        })
        openModal(ModalType.SuccessOrderExtraMoney)()
      } else {
        modalAnalyticsEvent.orderError({
          selectedCredit,
          creditsWithFee,
          creditsWithoutFee,
          error,
        })
        openModal(ModalType.Error)()
      }
    }
  }

  return { handleOrder }
}
