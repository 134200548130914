import React from 'react'

import { SubscriptionType } from 'src/api/subscription'
import { useSubscriptionState } from './hooks/useSubscriptionState'

export type SubscriptionContextProps = {
  subscriptionData: SubscriptionType | null
  shownSubscriptionNumber: string
  isSkeletonVisible: boolean
}

export const SubscriptionContext = React.createContext<SubscriptionContextProps | null>(null)

SubscriptionContext.displayName = 'SubscriptionContext'

export const SubscriptionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const value = useSubscriptionState()

  return <SubscriptionContext.Provider value={value}>{children}</SubscriptionContext.Provider>
}
