import { Hyperlink } from 'ks-react-design-system'

export const CHECK_BONUS_ACCOUNT_USSD = '*109#'

export default {
  title: 'Чекайте SMS, що нараховані Екстра гроші',
  text: 'Щоб повернути заборгованість, просто поповніться на',
  extraMoney: '(Екстра гроші)',
  commission: '(комісія)',
  check: (
    <>
      Перевірити бонусний рахунок —{' '}
      {
        <Hyperlink decoration={true} href={`tel:${encodeURIComponent(CHECK_BONUS_ACCOUNT_USSD)}`}>
          {CHECK_BONUS_ACCOUNT_USSD}
        </Hyperlink>
      }
    </>
  ),
}
