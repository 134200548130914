import { useCallback, useEffect, useMemo, useState } from 'react'

import { showcaseAnalyticsEvent } from 'src/analytics/events/showcase'
import { Subscription } from 'src/api/subscription'
import { SubscriptionContextProps } from '../context'
import phoneNumberWithSpacing from '../phoneNumberWithSpacing'

const subscription: Subscription = new Subscription()

export const useSubscriptionState = (): SubscriptionContextProps => {
  const [subscriptionData, setSubscriptionData] =
    useState<SubscriptionContextProps['subscriptionData']>(null)

  const [isSkeletonVisible, setSkeletonVisible] =
    useState<SubscriptionContextProps['isSkeletonVisible']>(true)

  const setSubscription = useCallback(async () => {
    setSkeletonVisible(true)

    const { data: responseData, error } = await subscription.get()

    if (responseData?.msisdn) {
      setSubscriptionData(responseData)
    }

    setSkeletonVisible(false)

    showcaseAnalyticsEvent.identify({
      msisdn: responseData?.msisdn,
      msisdnHash: responseData?.msisdnHash,
      ownerType: responseData?.ownerType,
      paymentType: responseData?.paymentType,
      subscriptionType: responseData?.subscriptionType,
      error,
    })
  }, [])

  useEffect(() => {
    void setSubscription()
  }, [setSubscription])

  return useMemo<SubscriptionContextProps>(
    () => ({
      subscriptionData,
      shownSubscriptionNumber: phoneNumberWithSpacing(subscriptionData?.msisdn || null),
      isSkeletonVisible,
    }),
    [subscriptionData, isSkeletonVisible],
  )
}
