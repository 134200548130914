import { Skeleton } from 'ks-react-design-system'

import styles from './MainSkeleton.module.scss'

export const MainSkeleton = () => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <Skeleton className={styles.phone} />
      <Skeleton className={styles.title} />
    </div>
    <div className={styles.body}>
      <Skeleton className={styles.topUp} />
      <Skeleton className={styles.topUp} />
      <Skeleton className={styles.topUp} />
    </div>
  </div>
)
