import React from 'react'

import { ModalType } from './modalType'

export type OpenModalFunction = (modalType: ModalType) => VoidFunction

export type ModalProps = Partial<{
  title: string
  label: string
  goBack: VoidFunction
  size: 's' | 'm' | 'l' | 'xl'
  fullScreen: boolean
  className: string
  footerProps: Partial<{
    text: string
    handler: VoidFunction
    asyncHandler: () => Promise<void>
    link: string
    isCentered: boolean
  }>
}>

export type ModalContextProps = {
  openModal: OpenModalFunction
  closeModal: VoidFunction
  updateModalProps: (props: ModalProps) => void
  modalType: ModalType | null
  modalProps: ModalProps | null
  modalComponent: React.JSX.Element | null
}

export const ModalContext = React.createContext<ModalContextProps | null>(null)

ModalContext.displayName = 'ModalContext'
