import dotenvParseVariables from 'dotenv-parse-variables'

const isValueArray = (value: string) => {
  try {
    return Array.isArray(JSON.parse(value))
  } catch (error) {
    return false
  }
}

const filteredEnv = Object.entries(process.env).reduce((acc, [key, value]) => {
  // There are native env variables (WDS_SOCKET_HOST WDS_SOCKET_PATH WDS_SOCKET_PORT) which are undefined that's why parsing function throws an error
  // Uncaught TypeError: Cannot read properties of undefined (reading 'toString')
  if (value === undefined) {
    return acc
  }

  // checking isValueArray needed to correct parse map value in string to regular map, dotenvParseVariables not covering this case
  return {
    ...acc,
    [key]: isValueArray(value) ? JSON.parse(value) : value,
  }
}, {})

const parsedEnv = dotenvParseVariables(filteredEnv)

const env: EnvType = { ...parsedEnv, ...window.env }

export const { REACT_APP_TAG_MANAGER_ENABLED } = env
