import { memo } from 'react'
import { ListItem, UnorderedList } from 'ks-react-design-system'

import content from './content'

import styles from './PayOptionsList.module.scss'

export const PayOptionsList = memo(() => (
  <UnorderedList listSize="m" className={styles.list}>
    {content.payOptions.map((option, index) => (
      <ListItem className={styles.listItem} key={index}>
        {option}
      </ListItem>
    ))}
  </UnorderedList>
))
