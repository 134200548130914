export enum EventName {
  UserIdentified = 'nmlp_user_identified',
  ExtraMoney = 'nmlp_extramoney_event',
}

export enum EventAction {
  OpenClick = 'extramoney_options_open_click',
  OpenError = 'extramoney_options_open_error',
  OpenCredits = 'extramoney_options_open',
  OpenPrecheck = 'extramoney_option_confirm',
  OrderConfirm = 'extramoney_order_confirm',
  OrderSuccess = 'extramoney_order_success',
  OrderError = 'extramoney_order_error',
}

export type CommonEventType = {
  event?: EventName
}

export type InitEventType = {
  subscription_hash: string | null
  subscription_type: 'msisdn' | null
  subscription_segment: string | null
  error_description: number | null
} & CommonEventType

export type ActionEventType = {
  event_options: {
    action: string
    error_description?: string | null
    fee_available?: boolean
    no_fee_available?: boolean
    product_code?: string
    product_value?: number
    product_fee?: number
    ticket_system?: 'bss'
    order_id?: string
  } | null
} & CommonEventType
