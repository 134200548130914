import { Typography } from 'ks-react-design-system'

import { ErrorIcon } from 'src/icons'
import { ModalWrapper } from '../ModalWrapper'
import content from './content'

import styles from './ErrorModal.module.scss'

export const ErrorModal = () => (
  <ModalWrapper>
    <section className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <ErrorIcon />
      </div>

      <Typography variant="heading-2xs-strong">{content.title}</Typography>
      <Typography variant="paragraph-m">{content.description}</Typography>
    </section>
  </ModalWrapper>
)
