import { Typography } from 'ks-react-design-system'

import { useExtraMoney } from 'src/context/extraMoneyContext'
import { SuccessIcon } from 'src/icons'
import { ModalWrapper } from '../ModalWrapper'
import { withCurrency } from '../withCurrency'
import content from './content'

import styles from './SuccessOrderExtraMoneyModal.module.scss'

export const SuccessOrderExtraMoneyModal = () => {
  const { text, title, extraMoney, commission, check } = content
  const { selectedCredit } = useExtraMoney()

  const { value = 0, fee = 0 } = selectedCredit || {}
  const extraMoneyValues = `${text} ${withCurrency(value)} ${extraMoney}  + ${withCurrency(fee)} ${commission}. `

  return (
    <ModalWrapper className={styles.modalRoot}>
      <SuccessIcon className={styles.icon} />
      <Typography variant="heading-s-strong" className={styles.title} textAlign="center">
        {title}
      </Typography>
      <Typography variant="paragraph-m" className={styles.text} textAlign="center">
        {extraMoneyValues}
        {check}
      </Typography>
    </ModalWrapper>
  )
}
