import { useState } from 'react'

import { useModal } from 'src/context/modalContext'

export type UseModalFooterHandlerReturnType = { handleClick: () => Promise<void>; loader: boolean }

export const useModalFooterHandler = (): UseModalFooterHandlerReturnType => {
  const { modalProps } = useModal()
  const [loader, setLoader] = useState<boolean>(false)

  const { handler, asyncHandler } = modalProps?.footerProps || {}

  const handleClick = async () => {
    if (handler) {
      handler()

      return
    }

    if (asyncHandler) {
      setLoader(true)
      await asyncHandler()
      setLoader(false)
    }
  }

  return { handleClick, loader }
}
