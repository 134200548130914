import { useEffect } from 'react'

import { CreditType } from 'src/api/extraMoney'
import { useExtraMoney } from 'src/context/extraMoneyContext'

type UseDefaultCreditProps = {
  creditsWithoutFee: CreditType[]
  creditsWithFee: CreditType[]
}

export const useDefaultCredit = ({
  creditsWithoutFee,
  creditsWithFee,
}: UseDefaultCreditProps): void => {
  const { selectedCredit, changeActiveCredit } = useExtraMoney()

  useEffect(() => {
    if (!selectedCredit) {
      const defaultCredit = creditsWithoutFee[0] || creditsWithFee[0]

      changeActiveCredit(defaultCredit)
    }
  }, [selectedCredit, creditsWithoutFee, creditsWithFee, changeActiveCredit])
}
