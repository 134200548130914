import { useExtraMoney } from 'src/context/extraMoneyContext'
import { DebtIcon } from 'src/icons'
import { PathName } from 'src/router'
import { ModalWrapper } from '../ModalWrapper'
import content from './content'
import { getExtraMoneyDebt } from './getExtraMoneyDebt'

import styles from './ExtraMoneyDebtModal.module.scss'

export const ExtraMoneyDebtModal = () => {
  const { title, description, footerTopUp } = content
  const { credits } = useExtraMoney()

  return (
    <ModalWrapper footerProps={{ text: footerTopUp, link: PathName.PayPath, isCentered: true }}>
      <section className={styles.debt}>
        <DebtIcon className={styles.icon} />
        <p className={styles.title}>
          {title} {getExtraMoneyDebt(credits)}
        </p>
        <p className={styles.description}>{description}</p>
      </section>
    </ModalWrapper>
  )
}
