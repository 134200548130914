import React from 'react'

import { useSubscription } from 'src/context/subscriptionContext'

import styles from './Pay.module.scss'

const url =
  'https://www.portmone.com.ua/r3/newkyivstar/?amount=30&entity=phone&shop_site_id=6044&lang=uk&pb=N'

export const Pay = () => {
  const { subscriptionData } = useSubscription()
  const subscriptionId = subscriptionData?.msisdn

  const subscriptionIdParam = subscriptionId ? `&phone=${subscriptionId}` : ''
  const src = `${url}${subscriptionIdParam}`

  return <iframe src={src} allow="payment" className={styles.iframe} />
}
