import React, { memo, ReactNode } from 'react'
import { ListItem, UnorderedList } from 'ks-react-design-system'

import styles from './TopUpOtherMethods.module.scss'

export type TopUpActionInfoItem = {
  title: string
  list: {
    id: number
    node: ReactNode
  }[]
}

export const TopUpOtherMethods: React.FC<{ item: TopUpActionInfoItem }> = memo(({ item }) => (
  <>
    <p className={styles.title}>{item.title}</p>

    {item.list && (
      <UnorderedList className={styles.list}>
        {item.list.map(el => (
          <ListItem key={el.id}>{el.node}</ListItem>
        ))}
      </UnorderedList>
    )}
  </>
))
