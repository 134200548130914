import React from 'react'
import { Choice, ChoiceGroup, Typography } from 'ks-react-design-system'

import { CreditType } from 'src/api/extraMoney'
import { withCurrency } from 'src/components/modals/withCurrency'
import { useExtraMoney } from 'src/context/extraMoneyContext'

import styles from './ExtraMoneyCredits.module.scss'

export type ExtraMoneyCreditsProps = {
  title: string
  credits: CreditType[]
}

export const ExtraMoneyCredits: React.FC<ExtraMoneyCreditsProps> = ({ title, credits }) => {
  const { selectedCredit, changeActiveCredit } = useExtraMoney()

  const handleChange = (credit: CreditType) => () => {
    changeActiveCredit(credit)
  }

  if (!credits?.length) {
    return null
  }

  return (
    <>
      <Typography className={styles.label} variant="label-m-strong">
        {title}
      </Typography>

      <ChoiceGroup className={styles.choiceGroup} direction="horizontal-inline">
        {credits.map((credit, index) => (
          <Choice
            className={styles.choice}
            key={index}
            value={selectedCredit?.value}
            checked={credit.value === selectedCredit?.value}
            onChange={handleChange(credit)}
          >
            {withCurrency(credit.value)}
          </Choice>
        ))}
      </ChoiceGroup>
    </>
  )
}
