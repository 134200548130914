import { CreditType } from 'src/api/extraMoney'
import { CommonEvent } from '../CommonEvent'
import { ActionEventType, EventAction, EventName } from '../types'

type OpenCreditsProps = {
  creditsWithFee: CreditType[]
  creditsWithoutFee: CreditType[]
}

type PrecheckEventProps = {
  selectedCredit: CreditType
  creditsWithFee: CreditType[]
  creditsWithoutFee: CreditType[]
}

type OrderSuccessProps = {
  selectedCredit: CreditType
  creditsWithFee: CreditType[]
  creditsWithoutFee: CreditType[]
  orderId: string
}

type OrderErrorProps = {
  selectedCredit: CreditType
  creditsWithFee: CreditType[]
  creditsWithoutFee: CreditType[]
  error: number | null
}

export class ModalEvent extends CommonEvent<ActionEventType> {
  public openError(error: number | null) {
    this.send({ event_options: null })
    this.send({
      event: EventName.ExtraMoney,
      event_options: {
        action: EventAction.OpenError,
        error_description: error?.toString() || null,
      },
    })
  }

  public openDebt() {
    this.send({ event_options: null })
    this.send({
      event: EventName.ExtraMoney,
      event_options: {
        action: EventAction.OpenError,
        error_description: 'debt_exists',
      },
    })
  }

  public openCredits({ creditsWithFee, creditsWithoutFee }: OpenCreditsProps) {
    this.send({ event_options: null })
    this.send({
      event: EventName.ExtraMoney,
      event_options: {
        action: EventAction.OpenCredits,
        fee_available: this.isAvailable(creditsWithFee),
        no_fee_available: this.isAvailable(creditsWithoutFee),
      },
    })
  }

  public openPrecheck({ selectedCredit, creditsWithFee, creditsWithoutFee }: PrecheckEventProps) {
    this.send({ event_options: null })
    this.send({
      event: EventName.ExtraMoney,
      event_options: {
        action: EventAction.OpenPrecheck,
        product_value: selectedCredit.value,
        product_fee: selectedCredit.fee,
        fee_available: this.isAvailable(creditsWithFee),
        no_fee_available: this.isAvailable(creditsWithoutFee),
      },
    })
  }

  public orderConfirm({ selectedCredit, creditsWithFee, creditsWithoutFee }: PrecheckEventProps) {
    this.send({ event_options: null })
    this.send({
      event: EventName.ExtraMoney,
      event_options: {
        action: EventAction.OrderConfirm,
        product_value: selectedCredit.value,
        product_fee: selectedCredit.fee,
        fee_available: this.isAvailable(creditsWithFee),
        no_fee_available: this.isAvailable(creditsWithoutFee),
      },
    })
  }

  public orderSuccess({
    selectedCredit,
    creditsWithFee,
    creditsWithoutFee,
    orderId,
  }: OrderSuccessProps) {
    this.send({ event_options: null })
    this.send({
      event: EventName.ExtraMoney,
      event_options: {
        action: EventAction.OrderSuccess,
        product_value: selectedCredit.value,
        product_fee: selectedCredit.fee,
        fee_available: this.isAvailable(creditsWithFee),
        no_fee_available: this.isAvailable(creditsWithoutFee),
        ticket_system: 'bss',
        order_id: orderId,
      },
    })
  }

  public orderError({ selectedCredit, creditsWithFee, creditsWithoutFee, error }: OrderErrorProps) {
    this.send({ event_options: null })
    this.send({
      event: EventName.ExtraMoney,
      event_options: {
        action: EventAction.OrderError,
        product_value: selectedCredit.value,
        product_fee: selectedCredit.fee,
        fee_available: this.isAvailable(creditsWithFee),
        no_fee_available: this.isAvailable(creditsWithoutFee),
        error_description: error?.toString() || null,
      },
    })
  }

  private isAvailable(credits: CreditType[]): boolean {
    return !!credits.length
  }
}
