import { Layout } from 'src/components/Layout'
import { RouterLink } from 'src/components/RouterLink'
import { PathName } from 'src/router'
import content from './content'

import styles from './NotFound.module.scss'

export const NotFound = () => (
  <Layout>
    <section className={styles.wrapper}>
      <p className={styles.title}>{content.title}</p>
      <p className={styles.subtitle}>{content.subtitle}</p>
      <RouterLink to={PathName.MainPath}>{content.buttonText}</RouterLink>
    </section>
  </Layout>
)
