import React, { memo } from 'react'
import { Button } from 'ks-react-design-system'

import { RouterLink } from 'src/components/RouterLink'

import styles from './TopUpAction.module.scss'

export type TopUpActionType = {
  title: string
  description: string
  buttonText: string
  href?: string
}

type TopUpActionProps = {
  item: TopUpActionType
  loader?: boolean
  onClick?: VoidFunction
}

export const TopUpAction: React.FC<TopUpActionProps> = memo(({ item, loader, onClick }) => (
  <>
    <p className={styles.title}>{item.title}</p>

    {item.description && <p className={styles.description}>{item.description}</p>}

    {onClick ? (
      <Button
        loader={loader}
        onClick={onClick}
        buttonSize="s"
        variant="primary"
        className={styles.button}
      >
        {item.buttonText}
      </Button>
    ) : (
      <RouterLink className={styles.button} to={item.href || ''}>
        {item.buttonText}
      </RouterLink>
    )}
  </>
))
