import React, { PropsWithChildren, useLayoutEffect } from 'react'

import { ModalProps, useModal } from 'src/context/modalContext'

export const ModalWrapper: React.FC<PropsWithChildren<ModalProps>> = ({ children, ...props }) => {
  const { updateModalProps } = useModal()

  useLayoutEffect(() => {
    updateModalProps(props)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateModalProps])

  return <>{children}</>
}
