import React, { PropsWithChildren } from 'react'

import { ModalRoot } from 'src/components/modals/ModalRoot'
import { useModal } from 'src/context/modalContext'

import 'ks-react-design-system/index.css'
import 'src/styles/index.scss'

export const App: React.FC<PropsWithChildren> = ({ children }) => {
  const { modalType } = useModal()

  return (
    <>
      {children}
      {modalType && <ModalRoot />}
    </>
  )
}
