import React from 'react'

export const SuccessIcon: React.FC<React.SVGAttributes<SVGElement>> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="256"
    height="144"
    fill="none"
    className={className}
  >
    <circle cx="128.333" cy="77.3333" r="66.6667" fill="#E9F5FF" />
    <circle cx="153" cy="93.3333" r="50.6667" fill="#F4D400" />
    <rect
      width="2.6667"
      height="2.6667"
      fill="#0087E6"
      stroke="#0087E6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      rx="1.3333"
      transform="matrix(-1 0 0 1 149.667 93.3333)"
    />
    <path
      stroke="#0087E6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M171 95c0-1.2887 1.045-2.3334 2.334-2.3334 1.288 0 2.333 1.0447 2.333 2.3334"
    />
    <rect width="6" height="4.6667" x="169.667" y="99.3333" fill="#EDBE00" rx="2.3333" />
    <rect width="6" height="4.6667" x="145.667" y="99.3333" fill="#EDBE00" rx="2.3333" />
    <path
      stroke="#0087E6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M163.667 102c0 1.657-1.343 3-3 3s-3-1.343-3-3"
    />
    <rect
      width="96.6667"
      height="112"
      x="96.3335"
      y="31.3334"
      stroke="#0087E6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      rx="48.3333"
    />
    <circle
      cx="201.419"
      cy="4.6666"
      r="2.6667"
      fill="#96C8FF"
      transform="rotate(-180 201.419 4.6666)"
    />
    <rect
      width="10.6667"
      height="10.6667"
      x="227.21"
      y="84.6666"
      fill="#C8E3FF"
      rx="5.3333"
      transform="rotate(-135 227.21 84.6666)"
    />
    <path
      fill="#C8E3FF"
      d="M43.1928 111.693c1.0841 1.789-.9583 3.832-2.747 2.747l-.6569-.398a2.0006 2.0006 0 0 0-2.0732 0l-.6569.398c-1.7888 1.085-3.8311-.958-2.747-2.747l.3981-.656a2.0016 2.0016 0 0 0 0-2.074l-.3981-.657c-1.0841-1.788.9582-3.831 2.747-2.747l.6569.399a2.0006 2.0006 0 0 0 2.0732 0l.6569-.399c1.7887-1.084 3.8311.959 2.747 2.747l-.3981.657a2.0011 2.0011 0 0 0 0 2.074l.3981.656Z"
    />
    <path
      stroke="#0087E6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M45.1928 113.027c1.0841 1.789-.9583 3.831-2.747 2.747l-.6569-.398a2.0006 2.0006 0 0 0-2.0732 0l-.6569.398c-1.7888 1.084-3.8311-.958-2.747-2.747l.3981-.657a1.9998 1.9998 0 0 0 0-2.073l-.3981-.657c-1.0841-1.789.9582-3.831 2.747-2.747l.6569.398a2.0006 2.0006 0 0 0 2.0732 0l.6569-.398c1.7887-1.084 3.8311.958 2.747 2.747l-.3981.657a1.9994 1.9994 0 0 0 0 2.073l.3981.657Z"
    />
    <rect
      width="10.6667"
      height="10.6667"
      x="28.542"
      y="17.3333"
      fill="#E9F5FF"
      rx="5.3333"
      transform="rotate(-135 28.542 17.3333)"
    />
  </svg>
)
