import { ListItem, Typography, UnorderedList } from 'ks-react-design-system'

import { SelectedCredit } from 'src/components/modals/SelectedCredit'
import { ModalType, useModal } from 'src/context/modalContext'
import { useSubscription } from 'src/context/subscriptionContext'
import { ModalWrapper } from '../ModalWrapper'
import content from './content'
import { useAnalytics } from './hooks/useAnalytics'
import { useHandleOrder } from './hooks/useHandleOrder'

import styles from './ExtraMoneyPreckeckModal.module.scss'

export const ExtraMoneyPrecheckModal = () => {
  const { shownSubscriptionNumber } = useSubscription()
  const { openModal, modalProps } = useModal()
  const { handleOrder } = useHandleOrder()

  const { title, label, termsTitle, termsList, terms, footerConfirm } = content

  useAnalytics({ hasMounted: modalProps?.title === title })

  return (
    <ModalWrapper
      title={title}
      label={`${label} ${shownSubscriptionNumber}`}
      goBack={openModal(ModalType.ExtraMoney)}
      footerProps={{ text: footerConfirm, asyncHandler: handleOrder }}
      className={styles.modalRoot}
    >
      <SelectedCredit />

      <section className={styles.termsWrapper}>
        <p className={styles.termsTitle}>{termsTitle}</p>
        {!!termsList?.length && (
          <UnorderedList>
            {termsList.map((termItem, index) => (
              <ListItem key={index}>{termItem}</ListItem>
            ))}
          </UnorderedList>
        )}
        <Typography className={styles.terms} variant="paragraph-s">
          {terms}
        </Typography>
      </section>
    </ModalWrapper>
  )
}
