import React from 'react'

import { TopUpAction } from 'src/components/TopUpAction'
import { TopUpOtherMethods } from 'src/components/TopUpOtherMethods'
import { useExtraMoney } from 'src/context/extraMoneyContext'
import { useSubscription } from 'src/context/subscriptionContext'
import content from './content'
import { useHandlers } from './useHandlers'

import styles from './TopUpActionsInfo.module.scss'

export const TopUpActionsInfo = () => {
  const { subscriptionData } = useSubscription()
  const { loading } = useExtraMoney()
  const { openModalHandler } = useHandlers()

  const { msisdn, isGsmPrepaid } = subscriptionData || {}

  return (
    <div className={styles.wrapper}>
      {msisdn && isGsmPrepaid && (
        <div className={styles.item}>
          <TopUpAction item={content.orderExtraMoney} loader={loading} onClick={openModalHandler} />
        </div>
      )}

      <div className={styles.item}>
        <TopUpAction item={content.topUpWithNoCommission} />
      </div>

      <div className={styles.item}>
        <TopUpOtherMethods item={content.topUpOtherMethods} />
      </div>
    </div>
  )
}
