import React from 'react'
import { Modal } from 'ks-react-design-system'

import { useModal } from 'src/context/modalContext'
import { ModalFooter } from './ModalFooter'

export const ModalRoot = () => {
  const { closeModal, modalProps, modalComponent } = useModal()

  return (
    <Modal
      title={modalProps?.title}
      label={modalProps?.label}
      goBack={modalProps?.goBack}
      size={modalProps?.size || 'm'}
      fullScreen={modalProps?.fullScreen || true}
      className={modalProps?.className}
      closeModal={closeModal}
      footer={<ModalFooter />}
    >
      {modalComponent}
    </Modal>
  )
}
