import { Button, LinkButton } from 'ks-react-design-system'
import classNames from 'classnames'

import { useModal } from 'src/context/modalContext'
import { useModalFooterHandler } from './useModalFooterHandler'

import styles from './ModalFooter.module.scss'

export const ModalFooter = () => {
  const { modalProps } = useModal()
  const { handleClick, loader } = useModalFooterHandler()

  if (!modalProps?.footerProps) {
    return null
  }

  const { text, link, isCentered = false } = modalProps.footerProps

  const wrapperClsNames = classNames(styles.wrapper, {
    [styles.wrapperCenter]: isCentered,
  })

  return (
    <div className={wrapperClsNames}>
      {!!link ? (
        <LinkButton href={link} className={styles.link}>
          {text}
        </LinkButton>
      ) : (
        <Button className={styles.button} onClick={handleClick} loader={loader}>
          {text}
        </Button>
      )}
    </div>
  )
}
