import TagManager, { DataLayerArgs } from 'react-gtm-module'

import { REACT_APP_TAG_MANAGER_ENABLED } from '../envVariables'
import { TagManagerClient } from './TagManagerClient'

export class GtmClient implements TagManagerClient {
  private readonly instance: typeof TagManager

  constructor() {
    this.instance = TagManager
  }

  public sendEvent(payload: DataLayerArgs['dataLayer']): void {
    if (window.dataLayer && REACT_APP_TAG_MANAGER_ENABLED) {
      this.instance.dataLayer({ dataLayer: payload })
    }
  }
}
