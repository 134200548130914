import React from 'react'

import { ModalContext } from 'src/context/modalContext/context'
import { useModalState } from 'src/context/modalContext/hooks/useModalState'

export const ModalProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const value = useModalState()

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}
