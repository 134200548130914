import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'

import { App } from './components/App'
import { ExtraMoneyProvider } from './context/extraMoneyContext'
import { ModalProvider } from './context/modalContext'
import { SubscriptionProvider } from './context/subscriptionContext'
import { router } from './router'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <SubscriptionProvider>
      <ExtraMoneyProvider>
        <ModalProvider>
          <App>
            <RouterProvider router={router} />
          </App>
        </ModalProvider>
      </ExtraMoneyProvider>
    </SubscriptionProvider>
  </React.StrictMode>,
)
