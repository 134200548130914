import { useCallback, useMemo, useState } from 'react'

import { CreditType, ExtraMoney } from 'src/api/extraMoney'
import { FeeFilter } from 'src/context/extraMoneyContext/FeeFilter'
import { ExtraMoneyContextProps } from '../context'

const extraMoney = new ExtraMoney()
const feeFilter = new FeeFilter()

export const useExtraMoneyState = (): ExtraMoneyContextProps => {
  const [loading, setLoading] = useState<ExtraMoneyContextProps['loading']>(false)
  const [credits, setCredits] = useState<ExtraMoneyContextProps['credits']>(null)
  const [creditsState, setCreditsState] = useState<ExtraMoneyContextProps['creditsState']>(null)
  const [selectedCredit, setSelectedCredit] =
    useState<ExtraMoneyContextProps['selectedCredit']>(null)

  const fetchCredits: ExtraMoneyContextProps['fetchCredits'] = useCallback(async () => {
    if (!credits) {
      setLoading(true)

      const { data: responseData, error } = await extraMoney.get()

      if (responseData) {
        setCredits(responseData.values)
        setCreditsState(responseData.state)
      }

      setLoading(false)

      return { error }
    }

    return { error: null }
  }, [credits])

  const changeActiveCredit: ExtraMoneyContextProps['changeActiveCredit'] = (credit: CreditType) => {
    setSelectedCredit(credit)
  }

  const creditsWithFee = useMemo(() => (credits ? feeFilter.with(credits) : []), [credits])
  const creditsWithoutFee = useMemo(() => (credits ? feeFilter.without(credits) : []), [credits])

  return useMemo(
    () => ({
      loading,
      credits,
      creditsWithFee,
      creditsWithoutFee,
      creditsState,
      selectedCredit,
      changeActiveCredit,
      fetchCredits,
    }),
    [
      loading,
      credits,
      creditsWithFee,
      creditsWithoutFee,
      creditsState,
      selectedCredit,
      fetchCredits,
    ],
  )
}
