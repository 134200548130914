import React from 'react'

export const ErrorIcon = () => (
  <svg width="320" height="88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M283.636 71.5c0 4.05-3.256 7.3333-7.272 7.3333-4.017 0-7.273-3.2833-7.273-7.3333 0-4.0501 3.256-7.3334 7.273-7.3334 4.016 0 7.272 3.2833 7.272 7.3334Z"
      fill="#E9F5FF"
    />
    <path
      d="M131.515 73.0277c0 3.2063-2.578 5.8056-5.757 5.8056-3.18 0-5.758-2.5993-5.758-5.8056s2.578-5.8055 5.758-5.8055c3.179 0 5.757 2.5992 5.757 5.8055Z"
      fill="#96C8FF"
    />
    <path
      d="M208.939 72.7223c0 2.7-2.171 4.8889-4.848 4.8889-2.678 0-4.849-2.1889-4.849-4.8889 0-2.7001 2.171-4.8889 4.849-4.8889 2.677 0 4.848 2.1888 4.848 4.8889Z"
      fill="#E9F5FF"
    />
    <path
      d="M116.688 60.1945c0 10.6314-8.548 19.25-19.0912 19.25-10.5436 0-19.0909-8.6186-19.0909-19.25 0-10.6315 8.5473-19.25 19.0909-19.25 10.5432 0 19.0912 8.6185 19.0912 19.25Z"
      fill="#C8E3FF"
    />
    <mask
      id="a"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="78"
      y="40"
      width="39"
      height="40"
    >
      <path
        d="M116.654 60.1774c0 10.6221-8.54 19.233-19.0741 19.233-10.5343 0-19.074-8.6109-19.074-19.233 0-10.622 8.5397-19.2329 19.074-19.2329 10.5341 0 19.0741 8.6109 19.0741 19.2329Z"
        fill="#F4D400"
      />
    </mask>
    <g mask="url(#a)">
      <path
        d="M118.983 45.1829H74.1502l-3.5916.1248v28.4724H114.4l3.344-3.3717h-41.86v-7.7425h45.823l6.193-8.4918H76.6271l-1.7339-4.4956h44.0898v-4.4956Z"
        fill="#E9F5FF"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.267 45.0714c5.405-1.7327 11.223 1.1826 12.983 6.5412 1.767 5.3644-1.21 11.1135-6.619 12.8481m0 0L83.7946 75.3154l-.0003.0001c-5.4034 1.7357-11.2178-1.1806-12.9832-6.5385l-.0001-.0003c-1.7645-5.3639 1.21-11.1131 6.6188-12.8478.3189-.1023.6598.0755.7612.3972.1015.3216-.0749.6653-.3938.7676-4.7833 1.5341-7.3817 6.5986-5.8358 11.2983 1.5507 4.7055 6.6768 7.2971 11.4652 5.7588h.0003L117.264 63.296c4.783-1.5342 7.384-6.5988 5.835-11.298v-.0006c-1.545-4.7047-6.673-7.2973-11.465-5.7612-.319.1022-.66-.0756-.761-.3973-.102-.3216.075-.6653.394-.7675"
      fill="#0087E6"
    />
    <path
      d="M34.0082 65.0747c-.3285-.5466.2904-1.1707.8324-.8394l4.8456 2.9611c.193.118.4351.118.6282 0l4.8455-2.9611c.5421-.3313 1.161.2928.8325.8394l-2.9367 4.8859a.6154.6154 0 0 0 0 .6334l2.9367 4.8859c.3285.5466-.2904 1.1707-.8325.8394l-4.8455-2.9611a.6016.6016 0 0 0-.6282 0l-4.8456 2.9611c-.542.3313-1.1609-.2928-.8324-.8394l2.9367-4.8859a.6154.6154 0 0 0 0-.6334l-2.9367-4.8859Z"
      fill="#E9F5FF"
    />
    <path
      d="M252.19 71.1857c-.329-.5465.29-1.1706.832-.8393l3.028 1.85c.193.118.435.118.628 0l3.027-1.85c.542-.3313 1.161.2928.833.8393l-1.835 3.0526a.6156.6156 0 0 0 0 .6335l1.835 3.0526c.328.5465-.291 1.1706-.833.8393l-3.027-1.85a.6013.6013 0 0 0-.628 0l-3.028 1.85c-.542.3313-1.161-.2928-.832-.8393l1.835-3.0526a.6156.6156 0 0 0 0-.6335l-1.835-3.0526ZM202.79 43.6945c0 20.4192-16.416 36.9722-36.667 36.9722-20.25 0-36.666-16.553-36.666-36.9722s16.416-36.9722 36.666-36.9722c20.251 0 36.667 16.553 36.667 36.9722Z"
      fill="#C8E3FF"
    />
    <path
      d="M193.448 50.0525c3.036-1.8839 8.871-.6702 8.871-.6702s-.94 9.0061-7.051 16.5902c-5.929 7.3576-12.222 10.428-12.222 10.428s-2.762-2.8096-3.144-4.2117c-.546-2.0006-.701-4.2388.7-6.3582 1.402-2.1195 5.635-3.5381 8.175-6.8294 2.404-3.117 1.634-7.0647 4.671-8.9487Z"
      fill="#fff"
    />
    <path
      d="M196.678 60.7583c3.097-2.37 4.231-5.214 4.231-5.214s0 2.37-5.641 10.4281c-5.422 7.7449-12.222 10.4281-12.222 10.4281s-2.762-2.8096-3.144-4.2117c-.546-2.0006-.701-4.2389.7-6.3583 1.402-2.1194 3.854.6159 9.495-1.2801 3.716-1.2489 3.485-1.422 6.581-3.7921Z"
      fill="#96C8FF"
    />
    <path
      d="M164.956 28.388c9.575-2.5903 10.509-11.3037 8.874-12.9521-1.634-1.6485-2.252-1.5826-3.269-1.6484-3.956-.2558-4.671 3.5324-9.342 4.0034-4.671.471-4.204-2.5905-8.174-1.884-3.97.7066-4.204 4.0034-3.97 6.1228.233 2.1193 6.305 8.9487 15.881 6.3583Z"
      fill="#fff"
    />
    <path
      d="M165.183 28.5268c9.629-2.7051 10.282-11.4425 8.647-13.0909-.649-.6551-.469 2.4286-1.126 4.0849-.657 1.6562-1.203 2.37-3.76 4.74-2.558 2.37-6.111 1.896-8.462 1.8961-2.351.0001-4.701 1.8959-4.701 1.8959s5.399 2.0349 9.402.474Z"
      fill="#96C8FF"
    />
    <path
      d="M135.062 41.5757c-.701-2.8259 0-6.8292 4.671-7.7712 4.671-.942 6.773 1.413 8.174 3.5324 1.401 2.1193 1.633 6.3953 4.204 10.126 2.918 4.2357 7.473 4.9454 9.341 8.7132 1.869 3.7679.234 7.0649-1.167 8.4778-1.402 1.4128-4.465 2.8202-7.707 2.355-3.193-.4582-5.021-1.6447-7.24-4.0034-3.732-3.9655-.323-8.9264-3.036-13.6585-2.067-3.6047-6.54-4.9453-7.24-7.7713Z"
      fill="#fff"
    />
    <path
      d="M135.062 41.5758c-1.845-5.4647 4.267-8.3087 4.671-7.7712.404.5374-3.216 4.9272-1.815 7.0466 1.401 2.1193 7.418 2.9052 9.989 6.636 2.918 4.2356 1.775 8.5562 3.644 12.3241 1.868 3.7678 10.135 3.4297 8.734 4.8426-1.402 1.4129-4.465 2.8202-7.707 2.355-3.193-.4581-5.021-1.6446-7.24-4.0034-3.732-3.9654-.323-8.9264-3.036-13.6585-2.067-3.6046-5.396-2.3065-7.24-7.7712Z"
      fill="#96C8FF"
    />
    <path
      d="M168.003 37.0589c0 1.0471-.841 1.896-1.88 1.896-1.038 0-1.88-.8489-1.88-1.896 0-1.0472.842-1.8961 1.88-1.8961 1.039 0 1.88.8489 1.88 1.8961ZM183.987 40.8503c0 2.0942-1.684 3.792-3.761 3.792s-3.761-1.6978-3.761-3.792c0-2.0943 1.684-3.7921 3.761-3.7921s3.761 1.6978 3.761 3.7921Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.3032 80.0556c0-.3375.2558-.6111.5714-.6111H289.126c.315 0 .571.2736.571.6111 0 .3375-.256.6111-.571.6111H30.8746c-.3156 0-.5714-.2736-.5714-.6111Z"
      fill="#0087E6"
    />
    <circle cx="236.061" cy="65.1515" r="13.0303" stroke="#0087E6" strokeWidth="1.2121" />
  </svg>
)
