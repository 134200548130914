import { Hyperlink } from 'ks-react-design-system'

export const TERMS_PDF_LINK = 'https://cdn.kyivstar.ua/nkw/b2c/common-docs/extramoney.pdf'

export default {
  title: 'Замовляємо Екстра гроші?',
  label: 'На номер',
  termsTitle: 'Важливий нюанс',
  termsList: [
    'Термінів повернення немає, комісія залишається фіксованою',
    'Повторно замовити можна, щойно погасите попередню заборгованість',
  ],
  terms: (
    <>
      Натискаючи «Підтвердити», ви погоджуєтеся з{' '}
      <Hyperlink href={TERMS_PDF_LINK} target="_blank">
        умовами послуги
      </Hyperlink>{' '}
      Екстра гроші
    </>
  ),
  footerConfirm: 'Підтвердити',
}
