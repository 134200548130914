import React from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'

import { Main } from 'src/routes/Main'
import { NotFound } from 'src/routes/NotFound'
import { Pay } from 'src/routes/Pay'

export enum PathName {
  MainPath = '/',
  PayPath = '/pay',
  NotFoundPath = '*',
}

export const routes: RouteObject[] = [
  {
    path: PathName.MainPath,
    element: <Main />,
  },
  {
    path: PathName.PayPath,
    element: <Pay />,
  },
  {
    path: PathName.NotFoundPath,
    element: <NotFound />,
  },
]

const router = createBrowserRouter(routes)

export default router
