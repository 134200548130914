import React from 'react'

import {
  ErrorModal,
  ExtraMoneyDebtModal,
  ExtraMoneyModal,
  ExtraMoneyPrecheckModal,
  SuccessOrderExtraMoneyModal,
} from 'src/components/modals'
import { ModalType } from './modalType'

export const modalComponents: Record<ModalType, React.JSX.Element> = {
  [ModalType.ExtraMoney]: <ExtraMoneyModal />,
  [ModalType.ExtraMoneyPrecheck]: <ExtraMoneyPrecheckModal />,
  [ModalType.ExtraMoneyDebt]: <ExtraMoneyDebtModal />,
  [ModalType.SuccessOrderExtraMoney]: <SuccessOrderExtraMoneyModal />,
  [ModalType.Error]: <ErrorModal />,
}
