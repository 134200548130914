import React from 'react'

import { Layout } from 'src/components/Layout'
import { MainSkeleton } from 'src/components/MainSkeleton'
import { TopUpActionsInfo } from 'src/components/TopUpActionsInfo'
import { TopUpTitle } from 'src/components/TopUpTitle'
import { useSubscription } from 'src/context/subscriptionContext'

export const Main = () => {
  const { isSkeletonVisible } = useSubscription()

  return (
    <Layout>
      {isSkeletonVisible ? (
        <MainSkeleton />
      ) : (
        <>
          <TopUpTitle />
          <TopUpActionsInfo />
        </>
      )}
    </Layout>
  )
}
