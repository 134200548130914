export default {
  byPhoneNumber: 'За номером',
  title: {
    authorized: 'Інтернет обмежено через брак коштів',
    unauthorized: 'Поповнення рахунку',
  },
  description: {
    authorized:
      'Щоб все запрацювало, треба поповнитися. Це можна зробити навіть без інтернету — виберіть зручний спосіб.',
    unauthorized: 'Виберіть зручний спосіб.',
  },
}
