import { SubscriptionType } from 'src/api/subscription'
import { CommonEvent } from '../CommonEvent'
import { ActionEventType, EventAction, EventName, InitEventType } from '../types'

type IdentifyProps = {
  error: number | null
} & Partial<Omit<SubscriptionType, 'isGsmPrepaid'>>

export class ShowcaseEvent extends CommonEvent<InitEventType | ActionEventType> {
  public identify(props: IdentifyProps) {
    const { msisdn, msisdnHash, ownerType, paymentType, subscriptionType, error } = props
    const segment = ownerType ? `${ownerType}_${paymentType}_${subscriptionType}` : null

    this.send({
      event: EventName.UserIdentified,
      subscription_hash: msisdnHash || null,
      subscription_type: msisdn ? 'msisdn' : null,
      subscription_segment: segment,
      error_description: error,
    })
  }

  public clickShowExtraMoney() {
    this.send({ event_options: null })
    this.send({
      event: EventName.ExtraMoney,
      event_options: {
        action: EventAction.OpenClick,
      },
    })
  }
}
