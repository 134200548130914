import React from 'react'

type DebtIconIconProps = {
  className?: string
}

export const DebtIcon: React.FC<DebtIconIconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="256"
    height="144"
    fill="none"
    viewBox="0 0 256 144"
    className={className}
  >
    <g clipPath="url(#clip0_4539_2288)">
      <path fill="#fff" d="M0 0H250V144H0z" transform="translate(3)"></path>
      <circle cx="128.333" cy="77.333" r="66.667" fill="#E9F5FF"></circle>
      <ellipse cx="153" cy="93.333" fill="#F4D400" rx="50.667" ry="50.667"></ellipse>
      <path
        stroke="#0087E6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M174.333 92.667L170 97h5M147.334 92.667L151.667 97h-5"
      ></path>
      <rect width="6" height="4.667" x="169.667" y="99.333" fill="#EDBE00" rx="2.333"></rect>
      <rect width="6" height="4.667" x="145.667" y="99.333" fill="#EDBE00" rx="2.333"></rect>
      <path
        stroke="#0087E6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M163.667 105a3 3 0 10-6 0"
      ></path>
      <rect
        width="96.667"
        height="112"
        x="96.334"
        y="31.333"
        stroke="#0087E6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        rx="48.333"
      ></rect>
    </g>
    <defs>
      <clipPath id="clip0_4539_2288">
        <path fill="#fff" d="M0 0H250V144H0z" transform="translate(3)"></path>
      </clipPath>
    </defs>
  </svg>
)
