import axios, { AxiosError, AxiosInstance } from 'axios'

import { logger } from 'src/logger'

export type CreditType = {
  serviceId: string
  fee: number
  value: number
  units: string
}

export enum StateVariant {
  Active = 'active',
  Available = 'available',
}

export type ExtraMoneyType = {
  state: StateVariant
  values: CreditType[]
}

export type GetExtraMoneyResponseType = {
  data: ExtraMoneyType | null
  error: number | null
}

export type ExtraMoneyOrderType = {
  orderId: string
}

export type OrderExtraMoneyResponseType = {
  data: ExtraMoneyOrderType | null
  error: number | null
}

type ExtraMoneyOrderBody = {
  serviceId: CreditType['serviceId']
  value: CreditType['value']
}

export class ExtraMoney {
  public async get(): Promise<GetExtraMoneyResponseType> {
    try {
      const response = await this.instance().get<ExtraMoneyType>('/api/extra-money')

      return {
        data: response.data,
        error: null,
      }
    } catch (err) {
      const error = err as AxiosError
      logger.error(error, 'ExtraMoney.get()')

      return {
        data: null,
        error: error.response?.status || null,
      }
    }
  }

  public async order(
    serviceId: CreditType['serviceId'],
    value: CreditType['value'],
  ): Promise<OrderExtraMoneyResponseType> {
    try {
      const body: ExtraMoneyOrderBody = {
        serviceId,
        value,
      }

      const response = await this.instance().post<ExtraMoneyOrderType>(
        '/api/extra-money/order',
        body,
      )

      return {
        data: response.data,
        error: null,
      }
    } catch (err) {
      const error = err as AxiosError
      logger.error(error, 'ExtraMoney.order()')

      return {
        data: null,
        error: error.response?.status || null,
      }
    }
  }

  private instance(): AxiosInstance {
    return axios.create()
  }
}
