import { useEffect } from 'react'

import { modalAnalyticsEvent } from 'src/analytics/events/modal'
import { useExtraMoney } from 'src/context/extraMoneyContext'

type UseAnalyticsProps = {
  hasMounted: boolean
}

export const useAnalytics = ({ hasMounted }: UseAnalyticsProps) => {
  const { selectedCredit, creditsWithFee, creditsWithoutFee } = useExtraMoney()

  useEffect(() => {
    if (hasMounted && selectedCredit) {
      modalAnalyticsEvent.openPrecheck({ selectedCredit, creditsWithFee, creditsWithoutFee })
    }
  }, [hasMounted, creditsWithFee, creditsWithoutFee, selectedCredit])
}
