import React, { useContext } from 'react'

export const createContextHook =
  <T>(context: React.Context<T>, errorMessage?: string) =>
  () => {
    const contextValue = useContext(context)

    if (contextValue === null) {
      throw new Error(errorMessage)
    }

    return contextValue
  }
