import React, { PropsWithChildren } from 'react'

import { LogoIcon } from 'src/icons'
import content from './content'

import styles from './Layout.module.scss'

export const Layout: React.FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.wrapper}>
    <header>
      <p className={styles.logo}>
        <LogoIcon />
      </p>
    </header>

    <main>{children}</main>

    <footer className={styles.footer}>
      &copy; 1997 - {new Date().getFullYear()} {content.copyright}
    </footer>
  </div>
)
