import { CreditType } from 'src/api/extraMoney'
import { withCurrency } from 'src/components/modals/withCurrency'

export const getExtraMoneyDebt = (credits: CreditType[] | null): string | null => {
  if (!credits?.length) return null

  const [credit] = credits

  return withCurrency(credit.value + credit.fee)
}
